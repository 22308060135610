import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { motion } from "framer-motion"
import {
  ArticleContainer,
  CopyText,
  Wrapper,
  ArticleUl,
  ArticleLi,
  Navigation,
  ArticleWrapper,
  ArticleDetailsWrapper,
  Header,
  Headline,
  NavWrapper,
} from "../../styles/components/products"
import { HeroA } from "../../styles/IndexStyles"

const Leuchten = ({ data, props }) => {
  const content = data.allWpPage.nodes[0]
  return (
    <Layout>
      <SEO title={content.title} />
      <ArticleContainer>
        <Header>
          <Headline>Unsere {content.title}</Headline>
          <CopyText>{content.pageTypeProduktUebersicht.bodycopy}</CopyText>
        </Header>
        <Wrapper>
          <Navigation>
            <NavWrapper>
              <ul>
                <li>
                  <Link></Link>
                </li>
                <li>
                  <Link></Link>
                </li>
                <li>
                  <Link></Link>
                </li>
              </ul>
            </NavWrapper>
          </Navigation>
          <ArticleWrapper>
            <Img fluid={content.pageTypeProduktUebersicht.specialimg.localFile.childImageSharp.fluid} alt="" />
            <div style={{marginTop: "2.5rem", marginBottom: "2.5rem"}}>
            <h2 style={{fontSize: "2rem", fontWeight: "600", marginBottom: "1rem"}}>Teppich Match:</h2><p style={{fontSize: "1.2rem", marginBottom: "1rem"}}> Große Auswahl an Materialien, Farben und Mustern. Mit der passenden Beratung gesamtkonzeptionell zum Traumteppich im Wunschmaß. Lassen Sie sich inspirieren.</p>
            <HeroA href="mailto:info@diewohnplaner.de?subject=Teppich%20Match">Jetzt zur persönlichen Beratung.</HeroA> 
            </div>
            <ArticleUl>
              {content.pageTypeProduktUebersicht.products.map(i => (
                <ArticleLi
                  whileHover={{
                    scale: 1.01,
                    boxShadow: "0 5px 15px lightgrey",
                  }}
                >
                  <article
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Link to={`/teppiche/${i.link.slug}`}>
                      <Img fluid={i.img.localFile.childImageSharp.fluid} alt=""  />
                      <motion.div
                        style={{
                          opacity: 1,
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        <ArticleDetailsWrapper>
                          <h2>{i.name}</h2>
                          <h3>{i.price}</h3>
                        </ArticleDetailsWrapper>
                      </motion.div>
                    </Link>
                  </article>
                </ArticleLi>
              ))}
            </ArticleUl>
          </ArticleWrapper>
          <div></div>
        </Wrapper>
      </ArticleContainer>
    </Layout>
  )
}

export default Leuchten

export const query = graphql`
  query {
    allWpPage(filter: { title: { eq: "Teppiche" } }) {
      nodes {
        title
        pageTypeProduktUebersicht {
          bodycopy
          specialimg {
            localFile {
              childImageSharp {
                fluid(cropFocus: CENTER, quality: 99) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          products {
            name
            price
            link {
              ... on WpTeppich {
                slug
              }
            }
            img {
              localFile {
                childImageSharp {
                  fluid(cropFocus: CENTER, quality: 99) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
